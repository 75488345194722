import {
  Footer,
  GlobalPageStyles,
  PageHeader,
  TextPageContent,
  TextPageLink,
  TextPageSection,
  TextPageSectionTitle,
  TextPageTitle
} from "../components/common";
import {Helmet} from 'react-helmet'

import * as React from "react";


const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>DFace - Privacy Policy</title>
      </Helmet>
      <GlobalPageStyles/>
      <PageHeader/>
      <TextPageContent>
        <TextPageTitle>Privacy Policy</TextPageTitle>
        <TextPageSectionTitle>
          Privacy Policy
        </TextPageSectionTitle>
        <TextPageSection>
          Ivan Zhuk built the D.Face app as a Freemium app. This SERVICE is provided by Ivan Zhuk at no cost
          and is intended for use as is.
        </TextPageSection>
        <TextPageSection>
          This page is used to inform visitors regarding my policies with the collection, use, and disclosure
          of Personal Information if anyone decided to use my Service.
        </TextPageSection>
        <TextPageSection>
          If you choose to use my Service, then you agree to the collection and use of information in relation
          to this policy. The Personal Information that I collect is used for providing and improving the
          Service. I will not use or share your information with anyone except as described in this Privacy
          Policy.
        </TextPageSection>
        <TextPageSection>
          The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which
          is accessible at D.Face unless otherwise defined in this Privacy Policy.
        </TextPageSection>
        <TextPageSectionTitle>
          Information Collection and Use
        </TextPageSectionTitle>
        <ul>
          <li>
            <TextPageSection>
              <strong>Face Data.</strong>
              The main purpose of our application is to process face on video. As soon as the transmitted
              request is processed, we immediately delete all the transmitted data. Our application is not
              collecting any face data or face features. We do not store user's face data anywhere. We do
              not use face data in any kind of Service-related activity except for the main purpose of
              application. We do not share face data with any third parties.
            </TextPageSection>
          </li>
          <li>
            <TextPageSection>
              <strong>Usage Data. </strong>For a better experience, while using our Service, I may require
              you
              to provide us with certain personally identifiable information, including but not limited to
              None. The information that I request will be retained on your device and is not collected by
              me
              in any way.
            </TextPageSection>
            <TextPageSection>
              The app does use third-party services that may collect information used to identify you.
            </TextPageSection>
            <TextPageSection>
              Link to privacy policy of third party service providers used by the app
            </TextPageSection>
            <ul>
              <li>
                <TextPageLink href="https://policies.google.com/privacy" target="_blank">
                  Google Play Services
                </TextPageLink>
              </li>
              <li>
                <TextPageLink href="https://appodeal.com/privacy-policy/" target="_blank">
                  Appodeal
                </TextPageLink>
              </li>
              <li>
                <TextPageLink href="https://amplitude.com/privacy" target="_blank">
                  Amplitude
                </TextPageLink>
              </li>
            </ul>
          </li>
          <li>
            <TextPageSection>
              <strong>Cookies.</strong> Cookies are files with a small amount of data that are commonly
              used as anonymous unique identifiers. These are sent to your browser from the websites that
              you visit and are stored on your device's internal memory.
            </TextPageSection>
            <TextPageSection>
              This Service does not use these “cookies” explicitly. However, the app may use third-party
              code
              and libraries that use “cookies” to collect information and improve their services. You have
              the
              option to either accept or refuse these cookies and know when a cookie is being sent to your
              device. If you choose to refuse our cookies, you may not be able to use some portions of
              this
              Service.
            </TextPageSection>
          </li>
        </ul>
        <TextPageSectionTitle>
          Log Data
        </TextPageSectionTitle>
        <TextPageSection>
          I want to inform you that whenever you use my Service, in a case of an error in the app I collect
          data and information (through third-party products) on your phone called Log Data. This Log Data may
          include information such as your device Internet Protocol (“IP”) address, device name, operating
          system version, the configuration of the app when utilizing my Service, the time and date of your
          use of the Service, and other statistics.
        </TextPageSection>
        <TextPageSectionTitle>
          Service Providers
        </TextPageSectionTitle>
        <TextPageSection>
          I may employ third-party companies and individuals due to the following reasons:
          <ul>
            <li>
              To facilitate our Service;
            </li>
            <li>
              To provide the Service on our behalf;
            </li>
            <li>
              To perform Service-related services; or
            </li>
            <li>
              To assist us in analyzing how our Service is used.
            </li>
          </ul>
          I want to inform users of this Service that these third parties have access to their Personal
          Information. The reason is to perform the tasks assigned to them on our behalf. However, they are
          obligated not to disclose or use the information for any other purpose.
        </TextPageSection>
        <TextPageSectionTitle>
          Security
        </TextPageSectionTitle>
        <TextPageSection>
          I value your trust in providing us your Personal Information, thus we are striving to use
          commercially acceptable means of protecting it. But remember that no method of transmission over the
          internet, or method of electronic storage is 100% secure and reliable, and I cannot guarantee its
          absolute security.
        </TextPageSection>
        <TextPageSectionTitle>
          Links to Other Sites
        </TextPageSectionTitle>
        <TextPageSection>
          This Service may contain links to other sites. If you click on a third-party link, you will be
          directed to that site. Note that these external sites are not operated by me. Therefore, I strongly
          advise you to review the Privacy Policy of these websites. I have no control over and assume no
          responsibility for the content, privacy policies, or practices of any third-party sites or services.
        </TextPageSection>
        <TextPageSectionTitle>
          Children’s Privacy
        </TextPageSectionTitle>
        <TextPageSection>
          These Services do not address anyone under the age of 13. I do not knowingly collect personally
          identifiable information from children under 13 years of age. In the case I discover that a child
          under 13 has provided me with personal information, I immediately delete this from our servers. If
          you are a parent or guardian and you are aware that your child has provided us with personal
          information, please contact me so that I will be able to do the necessary actions.
        </TextPageSection>
        <TextPageSectionTitle>
          Changes to This Privacy Policy
        </TextPageSectionTitle>
        <TextPageSection>
          I may update our Privacy Policy from time to time. Thus, you are advised to review this page
          periodically for any changes. I will notify you of any changes by posting the new Privacy Policy on
          this page.
        </TextPageSection>
        <TextPageSection>
          This policy is effective as of 2021-06-12
        </TextPageSection>
        <TextPageSectionTitle>
          Contact Us
        </TextPageSectionTitle>
        <TextPageSection>
          If you have any questions or suggestions about my Privacy Policy, do not hesitate to contact me
          at <TextPageLink href="mailto:privacy@dface.co">privacy@dface.co</TextPageLink>.
        </TextPageSection>
      </TextPageContent>
      <Footer/>
    </>
  )
}

export default PrivacyPolicy
